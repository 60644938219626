<template>
  <div>
    <b-overlay :show="isBusy" :opacity="0.88">
      <template #overlay>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Please Wait....</strong>
        </div>
      </template>
      <router-view></router-view>
    </b-overlay>
  </div>
</template>

<script>
import { bus } from "../../main";
import { APP_TYPES,STORES } from "../shared";
export default {
  computed: {
    initialized() {
      return this.$store.state.appsInitialized;
    },
  },
  data() {
    return {
      type: APP_TYPES.ISBN,
      isBusy: false,
    };
  },
  mounted() {
    if (!this.initialized) {
      this.getApplications();
    }
    bus.$on("reload-applications",()=>{
      this.getApplications();});
  },
  methods: {
    getApplications() {
      this.isBusy = true;
      this.$store.dispatch(STORES[this.type] + "/initApplications").then(() => {
        this.isBusy = false;
        this.$store.commit("set_appsInitialized");
      });
    },
  },
};
</script>

<style>
</style>